@font-face {
  font-family: 'icomoon';
  src: url('../webfonts/icomoon78ed.eot?orkqwr');
  src: url('../webfonts/icomoon78ed.eot?orkqwr#iefix') format('embedded-opentype'),
    url('../webfonts/icomoon78ed.ttf?orkqwr') format('truetype'),
    url('../webfonts/icomoon78ed.woff?orkqwr') format('woff'),
    url('../webfonts/icomoon78ed.svg?orkqwr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e900";
}

.icon-facebook-1:before {
  content: "\e901";
}

.icon-twitter:before {
  content: "\e902";
}

.icon-linked-in-logo-of-two-letters:before {
  content: "\e903";
}

.icon-youtube-symbol:before {
  content: "\e904";
}

.icon-plus:before {
  content: "\e905";
}

.icon-plus-1:before {
  content: "\e906";
}

.icon-minus:before {
  content: "\e907";
}

.icon-minus-1:before {
  content: "\e908";
}

.icon-phone-call:before {
  content: "\e909";
}

.icon-message:before {
  content: "\e90a";
}

.icon-location:before {
  content: "\e90b";
}

.icon-down-arrow:before {
  content: "\e90c";
}

.icon-magnifying-glass:before {
  content: "\e90d";
}

.icon-plus-2:before {
  content: "\e90e";
}

.icon-caret:before {
  content: "\e90f";
}

.icon-double-chevron:before {
  content: "\e910";
}

.icon-construction:before {
  content: "\e911";
}

.icon-construction-1:before {
  content: "\e912";
}

.icon-construction-2:before {
  content: "\e913";
}

.icon-left-chevron:before {
  content: "\e914";
}

.icon-chevron:before {
  content: "\e915";
}

.icon-right-arrow:before {
  content: "\e916";
}

.icon-left-arrow:before {
  content: "\e917";
}

.icon-confirmation:before {
  content: "\e918";
}

.icon-telephone:before {
  content: "\e919";
}

.icon-phone-call-1:before {
  content: "\e91a";
}

.icon-wrench:before {
  content: "\e91b";
}

.icon-house-repair:before {
  content: "\e91c";
}

.icon-engineering:before {
  content: "\e91d";
}

.icon-factory:before {
  content: "\e91e";
}

.icon-factory-1:before {
  content: "\e91f";
}

.icon-crane:before {
  content: "\e920";
}

.icon-gas-station:before {
  content: "\e921";
}

.icon-express-delivery:before {
  content: "\e922";
}

.icon-renewable-energy:before {
  content: "\e923";
}

.icon-customer-support:before {
  content: "\e924";
}

.icon-pointed-star:before {
  content: "\e925";
}

.icon-visibility:before {
  content: "\e926";
}

.icon-heart:before {
  content: "\e927";
}

.icon-right-quotation-mark:before {
  content: "\e928";
}

.icon-instagram:before {
  content: "\e929";
}

.icon-play-button:before {
  content: "\e92a";
}

.icon-play-button-1:before {
  content: "\e92b";
}

.icon-user:before {
  content: "\e92c";
}

.icon-conversation:before {
  content: "\e92d";
}

.icon-pinterest:before {
  content: "\e92e";
}

.icon-send:before {
  content: "\e92f";
}

.icon-clock:before {
  content: "\e930";
}

.icon-email:before {
  content: "\e931";
}

.icon-envelope:before {
  content: "\e932";
}

.icon-pin:before {
  content: "\e933";
}

.icon-phone-call-2:before {
  content: "\e934";
}

.icon-growth:before {
  content: "\e935";
}

.icon-work-process:before {
  content: "\e936";
}

.icon-search:before {
  content: "\e937";
}

.icon-check:before {
  content: "\e938";
}

.icon-maintenance:before {
  content: "\e939";
}

.icon-planning:before {
  content: "\e93a";
}

.icon-worker:before {
  content: "\e93b";
}

.icon-roof:before {
  content: "\e93c";
}

.icon-shield:before {
  content: "\e93d";
}

.icon-mission:before {
  content: "\e93e";
}

.icon-construction-worker:before {
  content: "\e93f";
}

.icon-architect:before {
  content: "\e940";
}

.icon-checking:before {
  content: "\e941";
}

.icon-wallpaper:before {
  content: "\e942";
}

.icon-programmer:before {
  content: "\e943";
}

.icon-best-price:before {
  content: "\e944";
}

.icon-like:before {
  content: "\e945";
}

.icon-crane-1:before {
  content: "\e946";
}

.icon-helmet:before {
  content: "\e947";
}

.icon-analysis:before {
  content: "\e948";
}

.icon-quote-left:before {
  content: "\e949";
}

.icon-quotation:before {
  content: "\e94a";
}

.icon-account:before {
  content: "\e94b";
}

.icon-hook:before {
  content: "\e94c";
}

.icon-blueprint:before {
  content: "\e94d";
}

.icon-automated-process:before {
  content: "\e94e";
}

.icon-consumer-behavior:before {
  content: "\e94f";
}

.icon-strategy:before {
  content: "\e950";
}

.icon-development:before {
  content: "\e951";
}

.icon-share:before {
  content: "\e952";
}

.icon-right-arrow-1:before {
  content: "\e953";
}

.icon-check-1:before {
  content: "\e954";
}

.icon-checkmark:before {
  content: "\e955";
}

.icon-cloud-computing:before {
  content: "\e956";
}

.icon-pdf:before {
  content: "\e957";
}

.icon-help:before {
  content: "\e958";
}

.icon-maps-and-flags:before {
  content: "\e959";
}

.icon-mailbox:before {
  content: "\e95a";
}

.icon-sketch:before {
  content: "\e95b";
}

.icon-time-management:before {
  content: "\e95c";
}

.icon-calendar:before {
  content: "\e95d";
}

.icon-image-gallery:before {
  content: "\e95e";
}

.icon-wallpaper-1:before {
  content: "\e95f";
}

.icon-google-plus:before {
  content: "\e960";
}

.icon-phone-call-3:before {
  content: "\e961";
}

.icon-up-arrow:before {
  content: "\e962";
}